<template>
    <section>
        <modal ref="modalEliminarImagen" titulo="Eliminar Imagen" icon="trash" @guardar="eliminarImagen">
            <div class="row mx-0 justify-center mt-2 mb-4">
                ¿Desea Eliminar esta Imagen?
            </div>
        </modal>
    </section>
</template>
<script>
export default {
    data(){
        return{}
    },
    methods: {
        toggle(){
            this.$refs.modalEliminarImagen.toggle();
        },
        eliminarImagen(){
            this.$emit('eliminar')
            this.toggle()
        }
    }
}
</script>
